import React from "react";
import { motion } from "framer-motion";
import flyer from "../assets/flyer.png";

function Landing() {
  return (
    <div
      className="h-screen flex flex-col justify-center items-center bg-cover bg-center text-center text-white play px-4"
      style={{
        backgroundImage:
          "url('https://images.pexels.com/photos/159888/pexels-photo-159888.jpeg?auto=compress&cs=tinysrgb&w=2520&h=1500&dpr=1')",
      }}
    >
      <motion.h1
        className="text-3xl md:text-5xl font-bold mb-2 md:mb-4 anton"
        whileHover={{ scale: 1.1 }}
      >
        Pitch the PNW
      </motion.h1>
      <p className="text-lg md:text-xl mb-4 md:mb-6">November 30th, 2024</p>

      <motion.img
        src={flyer}
        alt="Event Flyer"
        className="w-full max-w-xs md:max-w-md mb-4 md:mb-6 rounded-lg shadow-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      />

      <motion.div
        className="bg-highlight text-white px-4 py-2 md:px-6 md:py-3 rounded shadow-lg hover:bg-white hover:text-highlight transform transition"
        whileHover={{ scale: 1.1 }}
      >
        <a
          href="https://forms.gle/orcDnj3Ry2YAMYkr6"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register Now
        </a>
      </motion.div>
    </div>
  );
}

export default Landing;
