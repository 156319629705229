import React from "react";
import { motion } from "framer-motion";

function Format() {
  return (
    <div className="py-16 bg-dark text-highlight text-center px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8 anton text-white">
        Format
      </h2>
      <p className="text-lg font-semibold mb-2 px-4 sm:px-6 md:px-12 lg:px-24 play">
        Teams of up to three members will have the opportunity to demonstrate
        their ability to evaluate and pitch a stock based on the announced
        topic. Each team will be given a few hours to research, prepare, and
        present their investment proposal to a panel of esteemed judges, 
        backed by a strong investment thesis, data, valuation metrics,
        catalysts, and an assessment of the risk factors. Contact us @interlakestocksclub@gmail.com for more information or questions.
      </p>
    </div>
  );
}

export default Format;
