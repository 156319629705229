import React from 'react';
import Header from './components/Header';
import Landing from './components/Landing';
import Timeline from './components/Timeline';
import PrizesAndSpeakers from './components/PrizesAndSpeakers';
import Sponsors from './components/Sponsors';
import Format from './components/Format';
import Footer from './components/Footer';

function App() {
  return (
    <div className="font-sans">
      <Header />
      <Landing />
      <Timeline />
      {/* <PrizesAndSpeakers /> */}
      <Sponsors />
      <Format />
      <Footer />
    </div>
  );
}

export default App;
