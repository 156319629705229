import React from "react";
import { motion } from "framer-motion";
import logo from "../assets/logo.png";

function Header() {
  return (
    <header className="flex flex-wrap justify-between items-center p-4 bg-darkGray text-white play">
      <div className="flex items-center space-x-4">
        <img src={logo} alt="Logo" className="h-12" />
        <span className="text-sm md:text-base">
          November 30th, 2024 @Bellevue Library
        </span>
      </div>
      <div className="flex space-x-4 mt-4 md:mt-0">
        <motion.div
          className="bg-highlight text-white px-6 py-3 rounded shadow-lg transform transition align-right hover:bg-white hover:text-highlight"
          whileHover={{ scale: 1.1 }}
        >
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdxCdrXBH31Nk8cKzguLVaLnPRhdH5GS6I8uw0KiHS4q9M9bA/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            Partner
          </a>
        </motion.div>

        <motion.div
          className="bg-white text-highlight px-6 py-3 rounded shadow-lg transform transition  hover:bg-highlight hover:text-white"
          whileHover={{ scale: 1.1 }}
        >
          <a
            href="https://forms.gle/orcDnj3Ry2YAMYkr6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register
          </a>
        </motion.div>
      </div>
    </header>
  );
}

export default Header;
