import React from 'react';
import { motion } from 'framer-motion';

function Timeline() {
  const events = [
    { time: '11.00 AM', activity: 'Check In & Registration' },
    { time: '11.50 AM', activity: 'Introduction' },
    { time: '11.25 AM', activity: 'Pitch Prep Time' },
    { time: '12.30 PM', activity: 'Lunch Break' },
    { time: '1.15 PM', activity: 'Final Prep Time' },
    { time: '2.15 PM', activity: 'Judging Begins' },
    { time: '3.45 PM', activity: 'Finalists Present' },
    { time: '4.30 PM', activity: 'Closing Ceremony' },
    { time: '5.00 PM', activity: 'End of Competition :(' }
  ];

  const half = Math.ceil(events.length / 2);
  const leftColumnEvents = events.slice(0, half);
  const rightColumnEvents = events.slice(half);

  return (
    <div className="py-16 bg-dark text-white text-center px-4 sm:px-6 lg:px-8 play">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8 anton">Event Day Timeline</h2>
      <div className="relative max-w-4xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8">
          <div className="space-y-6">
            {leftColumnEvents.map((event, index) => (
              <div
                key={index}
                className="relative flex items-center space-x-4 lg:space-x-6"
              >
                <div className="relative flex items-center justify-center w-6 h-6 bg-highlight rounded-full z-10">
                  {index < leftColumnEvents.length - 1 && (
                    <div className="absolute left-1/2 transform -translate-x-1/2 w-1 h-16 bg-highlight top-8 lg:top-10"></div>
                  )}
                </div>
                <div className="flex-1 bg-darkGray p-4 rounded-lg shadow-lg">
                  <div className="text-xs sm:text-sm font-bold mb-1">{event.time}</div>
                  <h3 className="font-semibold text-sm sm:text-base">{event.activity}</h3>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full h-1 bg-highlight lg:hidden"></div>
          
          <div className="space-y-6">
            {rightColumnEvents.map((event, index) => (
              <motion.div
                key={index}
                className="relative flex items-center space-x-4 lg:space-x-6"
              >
                <div className="relative flex items-center justify-center w-6 h-6 bg-highlight rounded-full z-10">
                  {index < rightColumnEvents.length - 1 && (
                    <div className="absolute left-1/2 transform -translate-x-1/2 w-1 h-16 bg-highlight top-8 lg:top-10"></div>
                  )}
                </div>
                <div className="flex-1 bg-darkGray p-4 rounded-lg shadow-lg">
                  <div className="text-xs sm:text-sm font-bold mb-1">{event.time}</div>
                  <h3 className="font-semibold text-sm sm:text-base">{event.activity}</h3>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;
