import React from 'react';

function Footer() {
  return (
    <footer className="p-4 bg-highlight text-dark text-center play">
      <p>© 2024 Pitch the PNW. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
