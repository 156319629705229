import React from 'react';
import { motion } from 'framer-motion';

function Sponsors() {
  const sponsors = ['We currently have no sponsors. Become one today!'];

  return (
    <div 
      className="py-16 bg-cover bg-center text-center play bg-highlight"
      style={{ backgroundImage: "url('/path/to/sponsors-bg.jpg')" }}
    >
      <h2 className="text-3xl font-bold mb-8 anton text-white">Our Sponsors</h2>
      <div className="space-y-4 text-dark">
        {sponsors.map((sponsor, index) => (
          <motion.div 
            key={index}
            className="hover:text-white"
            whileHover={{ scale: 1.05 }}
          >
            {sponsor}
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Sponsors;
